var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","show-summary":true,"summary-method":_vm.handleSummary,"stripe":""},scopedSlots:_vm._u([{key:"customer_name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.customer ? row.customer.customer_name_en : '')+" ")]}},{key:"actual_payment_date",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"badge bg-info rounded-pill"},[_vm._v(_vm._s(row.actual_payment_date))])]}},{key:"principal_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.principal_paid, row.currency))+" ")]}},{key:"interest_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.interest_paid, row.currency))+" ")]}},{key:"monthly_fee_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.monthly_fee_paid, row.currency))+" ")]}},{key:"rescue_fee_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.rescue_fee_paid, row.currency))+" ")]}},{key:"actual_payment_amount",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"badge bg-primary rounded-pill"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode( row.actual_payment_amount, row.currency )))])]}},{key:"overdue_penalty_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode( row.overdue_penalty_paid, row.currency ))+" ")]}},{key:"reschedule_penalty_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatCurrencyWithCode( row.reschedule_penalty_paid, row.currency ))+" ")]}},{key:"pay_off_penalty_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatCurrencyWithCode( row.pay_off_penalty_paid, row.currency ))+" ")]}},{key:"outstanding_balance",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode( row.outstanding_balance, row.currency ))+" ")]}},{key:"amount_excess",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.amount_excess, row.currency))+" ")]}},{key:"is_approved",fn:function(ref){
var row = ref.row;
return [(row.is_approved)?_c('span',{staticClass:"badge bg-success tw-mr-1 tw-capitalize"},[_vm._v(" Yes ")]):_c('span',{staticClass:"badge bg-secondary tw-mr-1 tw-capitalize"},[_vm._v(" No ")])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(!row._deleting)?_c('Poptip',{attrs:{"title":_vm.$t('are_you_sure_to_delete'),"transfer":true,"width":"240"},on:{"on-popper-show":function () { return (_vm.model.deleted_reason = ''); }}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('journalEntry.reason')))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.deleted_reason),expression:"model.deleted_reason",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                            'is-invalid': _vm.$v.model.deleted_reason.$error
                        },attrs:{"type":"text"},domProps:{"value":(_vm.model.deleted_reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "deleted_reason", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.reasonErrors.length > 0)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.reasonErrors[0]))]):_vm._e(),_c('div',{staticClass:"tw-mt-2 tw-text-right"},[_c('a',{staticClass:"ivu-btn ivu-btn-primary ivu-btn-small",on:{"click":function($event){$event.preventDefault();return _vm.onDelete(row)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('ok')))])])])])]):_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }