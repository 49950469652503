<template>
    <div>
        <Table
            :columns="columns"
            :data="resources"
            size="small"
            :show-summary="true"
            :summary-method="handleSummary"
            stripe
        >
            <template slot-scope="{ row }" slot="customer_name">
                {{ row.customer ? row.customer.customer_name_en : '' }}
            </template>
            <template slot-scope="{ row }" slot="actual_payment_date">
                <span class="badge bg-info rounded-pill">{{
                    row.actual_payment_date
                }}</span>
            </template>
            <template slot-scope="{ row }" slot="principal_paid">
                {{ formatCurrencyWithCode(row.principal_paid, row.currency) }}
            </template>
            <template slot-scope="{ row }" slot="interest_paid">
                {{ formatCurrencyWithCode(row.interest_paid, row.currency) }}
            </template>
            <template slot-scope="{ row }" slot="monthly_fee_paid">
                {{ formatCurrencyWithCode(row.monthly_fee_paid, row.currency) }}
            </template>
            <template slot-scope="{ row }" slot="rescue_fee_paid">
                {{ formatCurrencyWithCode(row.rescue_fee_paid, row.currency) }}
            </template>
            <template slot-scope="{ row }" slot="actual_payment_amount">
                <span class="badge bg-primary rounded-pill">{{
                    formatCurrencyWithCode(
                        row.actual_payment_amount,
                        row.currency
                    )
                }}</span>
            </template>
            <template slot-scope="{ row }" slot="overdue_penalty_paid">
                {{
                    formatCurrencyWithCode(
                        row.overdue_penalty_paid,
                        row.currency
                    )
                }}
            </template>
            <template slot-scope="{ row }" slot="reschedule_penalty_paid"
                >{{
                    formatCurrencyWithCode(
                        row.reschedule_penalty_paid,
                        row.currency
                    )
                }}
            </template>
            <template slot-scope="{ row }" slot="pay_off_penalty_paid"
                >{{
                    formatCurrencyWithCode(
                        row.pay_off_penalty_paid,
                        row.currency
                    )
                }}
            </template>
            <template slot-scope="{ row }" slot="outstanding_balance">
                {{
                    formatCurrencyWithCode(
                        row.outstanding_balance,
                        row.currency
                    )
                }}
            </template>
            <template slot-scope="{ row }" slot="amount_excess">
                {{ formatCurrencyWithCode(row.amount_excess, row.currency) }}
            </template>
            <template slot-scope="{ row }" slot="is_approved">
                <span
                    v-if="row.is_approved"
                    class="badge bg-success tw-mr-1 tw-capitalize"
                >
                    Yes
                </span>
                <span v-else class="badge bg-secondary tw-mr-1 tw-capitalize">
                    No
                </span>
            </template>
            <template slot-scope="{ row }" slot="action">
                <Poptip
                    :title="$t('are_you_sure_to_delete')"
                    :transfer="true"
                    width="240"
                    v-if="!row._deleting"
                    @on-popper-show="() => (model.deleted_reason = '')"
                >
                    <a
                        class="ml-2 text-danger"
                        v-tooltip="$t('delete')"
                        :disabled="row._deleting"
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="row._deleting"
                        ></i>
                        <Icon type="ios-trash" size="20" v-else />
                    </a>
                    <div slot="content">
                        <label class="required">{{
                            $t('journalEntry.reason')
                        }}</label>
                        <input
                            v-model.trim="model.deleted_reason"
                            type="text"
                            class="form-control"
                            :class="{
                                'is-invalid': $v.model.deleted_reason.$error
                            }"
                        />
                        <span
                            class="invalid-feedback"
                            v-if="reasonErrors.length > 0"
                            >{{ reasonErrors[0] }}</span
                        >
                        <div class="tw-mt-2 tw-text-right">
                            <a
                                class="ivu-btn ivu-btn-primary ivu-btn-small"
                                @click.prevent="onDelete(row)"
                            >
                                <span>{{ $t('ok') }}</span>
                            </a>
                        </div>
                    </div>
                </Poptip>
                <a
                    v-else
                    class="ml-2 text-danger"
                    v-tooltip="$t('delete')"
                    :disabled="row._deleting"
                >
                    <i
                        class="fas fa-circle-notch fa-spin"
                        v-if="row._deleting"
                    ></i>
                    <Icon type="ios-trash" size="20" v-else />
                </a>
            </template>
        </Table>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
    name: 'tableExpand',
    props: {
        loan_disbursements: Array
    },
    data () {
        return {
            width: 'width: 300px',
            model: {
                deleted_reason: ''
            }
        }
    },
    validations: {
        model: {
            deleted_reason: { required }
        },
        form: ['model.deleted_reason']
    },
    computed: {
        ...mapGetters(['formatCurrencyWithCode']),
        resources () {
            let resources = []
            this.loan_disbursements.forEach(
                resource => {
                    resources.push(Object.assign(resource, { _deleting: false }))
                }
            )
            return resources
        },
        reasonErrors () {
            const errors = []
            if (!this.$v.model.deleted_reason.$error) return errors
            !this.$v.model.deleted_reason.required && errors.push('Required')
            return errors
        },
        columns () {
            return [
                {
                    title: this.$t('loanRepayment.loanNumber'),
                    key: 'loan_number',
                    sortable: true,
                    width: 150
                },
                {
                    title: this.$t('loanRepayment.customerName'),
                    key: 'customer_name',
                    slot: 'customer_name',
                    minWidth: 190,
                    sortable: true
                },
                {
                    title: this.$t('loanRepayment.cyclePaymentDate'),
                    key: 'cycle_payment_date',
                    sortable: true,
                    align: 'center',
                    width: 160
                },
                {
                    title: this.$t('loanRepayment.actualpaymentDate'),
                    key: 'actual_payment_date',
                    slot: 'actual_payment_date',
                    align: 'center',
                    sortable: true,
                    width: 180
                },
                {
                    title: this.$t('loanRepayment.principal'),
                    key: 'principal_paid',
                    slot: 'principal_paid',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.interest'),
                    key: 'interest_paid',
                    slot: 'interest_paid',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.monthlyFee'),
                    key: 'monthly_fee_paid',
                    slot: 'monthly_fee_paid',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.rescueFund'),
                    key: 'rescue_fee_paid',
                    slot: 'rescue_fee_paid',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.actualPaymentAmount'),
                    key: 'actual_payment_amount',
                    slot: 'actual_payment_amount',
                    minWidth: 150,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.overduePenalty'),
                    key: 'overdue_penalty_paid',
                    slot: 'overdue_penalty_paid',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.reschedulePenalty'),
                    key: 'reschedule_penalty_paid',
                    slot: 'reschedule_penalty_paid',
                    minWidth: 160,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.payOffPenalty'),
                    key: 'pay_off_penalty_paid',
                    slot: 'pay_off_penalty_paid',
                    minWidth: 130,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.outstandingBalance'),
                    key: 'outstanding_balance',
                    slot: 'outstanding_balance',
                    minWidth: 150,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.amountExcess'),
                    key: 'amount_excess',
                    slot: 'amount_excess',
                    minWidth: 150,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.isApproved'),
                    key: 'is_approved',
                    slot: 'is_approved',
                    minWidth: 100,
                    align: 'center',
                    fixed: 'right'
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    width: 85
                }
            ]
        }
    },
    methods: {
        handleSummary ({ columns, data }) {
            const sums = {}
            columns.forEach((column, index) => {
                const key = column.key
                if (index === 0) {
                    sums[key] = {
                        key,
                        value: this.$t('loanRepayment.total')
                    }
                    return
                }
                if (index === 10 || index === 12 || index === 13) {
                    sums[key] = {
                        key,
                        value: ''
                    }
                    return
                }
                const currency = data.map(item => item.currency)
                const values = data.map(item => Number(item[key]))
                if (!values.every(value => isNaN(value))) {
                    const v = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    sums[key] = {
                        key,
                        value: this.formatCurrencyWithCode(v, currency[0])
                    }
                } else {
                    sums[key] = {
                        key,
                        value: 'N/A'
                    }
                }
            })

            return sums
        },
        async onDelete (record) {
            this.$v.form.$touch()
            if (this.$v.form.$invalid) return

            record._deleting = true
            this.$store
                .dispatch('creditOperation/loanRepayment/destroy', {
                    id: record.transaction_id,
                    loan_id: record.loan_id,
                    payment_voucher: record.payment_voucher,
                    data: this.model
                })
                .then(response => {
                    return this.$store
                        .dispatch('creditOperation/loanRepayment/fetch', {
                            approve_status: record.is_approved,
                            actual_payment_date: record.actual_payment_date,
                            branch_id: record.branch_id
                        })
                        .then(() => {
                            this.notice({
                                type:
                                    response.message_id == 0
                                        ? 'success'
                                        : 'warning',
                                text: response.message
                            })
                        })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                    this.model.deleted_reason = ''
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'LOAN REPAYMENT',
                desc: not.text
            })
        }
    }
}
</script>
